import React, { Suspense } from 'react';
import LoaderApp from '../../ui/loaders/loader-app/loader-app';
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer';
import Documents from '../../blocks/documents/documents';

const DocumentsPage = () => {
  return (
    <Suspense fallback={<LoaderApp />}>
      <Header />
      <Documents />
      <Footer />
    </Suspense>
  );
};

export default DocumentsPage;
