import React from 'react';

const Documents = () => {
  return (
    <main>
      <section className="documents">
        <h2 className="documents__title">Документы</h2>
        <div className="documents__container">
          <ul className="documents__list">
            <li>
              <a className="documents__link" href="http://adc52.ru/Attestat.PDF" target="_blank">
                Аттестат аккредитации оператора технического осмотра ООО "АДС"
              </a>
            </li>
          </ul>
        </div>
      </section>
    </main>
  );
};

export default Documents;
